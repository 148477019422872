import { getLPMSTheme } from '../utils/theme';

const env = process.env.NODE_ENV || 'development';

/**
 * Create datadog config for the given environment
 * @returns {Object} Datadog RUM configuration
 */
export const getDatadogConfig = () => {
  return {
    applicationId: DATADOG_APP_ID,
    clientToken: DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'proof-lpms',
    env,
    version: APP_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
    allowedTracingOrigins: [/https:\/\/(.*\.)?proofserve\.com/],
    beforeSend: (event) => {
      // Add LPMS theme info to all events
      event.context = {
        ...event.context,
        lpms: getLPMSTheme(),
      };
      return event;
    },
    sourceMapsVersion: APP_VERSION,
  };
};

/**
 * Create datadog logs config for the given environment
 * @returns {Object} Datadog RUM configuration
 */
export const getDatadogLogsConfig = () => {
  return {
    clientToken: DATADOG_CLIENT_TOKEN,
    env: env,
    forwardErrorsToLogs: true,
    service: 'proof-lpms',
    sessionSampleRate: 100,
    site: 'datadoghq.com',
    version: APP_VERSION,
  };
};
