import { useRef, useState } from 'react';
import postHog from 'posthog-js';

export const usePostHogInit = () => {
  const postHogInstance = useRef(null);
  const [featureFlagsLoaded, setFeatureFlagsLoaded] = useState(false);

  if (!postHogInstance.current) {
    const instance = postHog.init(VITE_POSTHOG_KEY, {
      api_host: VITE_POSTHOG_DOMAIN,
      loaded: (loadedInstance) => {
        loadedInstance.onFeatureFlags(() => {
          setFeatureFlagsLoaded(true);
        });
      },
      session_recording: {
        maskAllInputs: false,
        maskInputOptions: {
          password: true,
        },
      },
    });

    if (instance) {
      postHogInstance.current = instance;
      if (!instance.sessionRecordingStarted({ sampling: true })) {
        instance.startSessionRecording();
      }
    }
  }
  return {
    featureFlagsLoaded,
  };
};
