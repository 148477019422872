import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import JobListScreen from 'screens/JobListScreen';
import { Routes, Route, MemoryRouter } from 'react-router-dom';
import JobDetailScreen from 'screens/JobDetailScreen';
import JobCreationScreen from 'screens/job-creation/JobCreationScreen';
import { client as axiosClient, getCurrentLawFirm } from 'utils/api';
import {
  CurrentUserContextProvider,
  CurrentUserContext,
} from 'context/CurrentUserContext';
import { MatterContextProvider, MatterContext } from 'context/MatterContext';
import SettingsScreen from 'screens/SettingsScreen';
import SupportScreen from 'screens/SupportScreen';
import { ToastProvider } from 'components/Toast';
import ErrorBoundary from 'components/ErrorBoundary';
import { getCookie } from 'utils/cookie';
import LoginScreen from 'screens/LoginScreen';
import EditFirmAddress from 'screens/modals/EditFirmAddress';
import { Provider as UrqlProvider } from 'urql';
import { client as urqlClient } from 'graphql/client';
import Button from 'components/Button';
import { usePostHogInit } from 'utils/posthog';
import { usePostHog } from 'posthog-js/react';
import Logo from 'components/Logo';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { isFirmAddressFullyPopulated } from './utils/funcs';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
`;

const SignOutContainer = styled.div`
  margin-top: 1rem;
  text-align: center;
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  width: 100%;
  height: 100%;
  background: #f3f3ec;
`;

const ErrorIllustration = styled.img`
  width: 486px;
  max-width: 90%;
  height: 312px;
  border-radius: 8px;
  margin: 2rem 0;
`;

const ErrorAlert = styled.div`
  padding: 16px;
  background: #fff1cc;
  border-radius: 8px;
  border: 1px solid rgba(239, 179, 1, 0.54);
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 486px;
  width: 100%;
`;

const ErrorTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  color: #ab6400;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`;

const ErrorMessage = styled.div`
  color: #ab6400;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
`;

const queryClient = new QueryClient();

const AppRoutes = () => (
  <Routes>
    <Route
      exact
      path="/"
      element={
        <ErrorBoundary name="JobListScreen">
          <JobListScreen />
        </ErrorBoundary>
      }
    />
    <Route
      exact
      path="/jobs/new"
      element={
        <ErrorBoundary name="JobCreationScreen">
          <JobCreationScreen />
        </ErrorBoundary>
      }
    />
    <Route
      exact
      path="/jobs/:jobID"
      element={
        <ErrorBoundary name="JobDetailScreen">
          <JobDetailScreen />
        </ErrorBoundary>
      }
    />
    <Route
      exact
      path="/settings"
      element={
        <ErrorBoundary name="SettingsScreen">
          <SettingsScreen />
        </ErrorBoundary>
      }
    />
    <Route
      exact
      path="/support"
      element={
        <ErrorBoundary name="SupportScreen">
          <SupportScreen />
        </ErrorBoundary>
      }
    />
  </Routes>
);

const LoginRoutes = () => (
  <Routes>
    <Route exact path="/" element={<LoginScreen />} />
    <Route exact path="/support" element={<SupportScreen />} />
  </Routes>
);

const AppContent = ({ theme }) => {
  const { error } = useContext(MatterContext);
  const currentUser = useContext(CurrentUserContext);
  const posthog = usePostHog();

  if (theme.id === 'FILEVINE') {
    if (error?.type === 'org_mismatch') {
      return (
        <ErrorContainer>
          <Logo />
          <ErrorIllustration
            src="/images/project-unavailable.gif"
            alt="Project unavailable"
          />
          <ErrorAlert>
            <ErrorTitle>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.83397 2.57335C8.54897 2.07335 7.79897 2.07335 7.51397 2.57335L1.51397 13.2399C1.22897 13.7399 1.59897 14.3733 2.16564 14.3733H14.1823C14.749 14.3733 15.119 13.7399 14.834 13.2399L8.83397 2.57335ZM8.16564 5.70668C8.16564 5.43335 8.39231 5.20668 8.66564 5.20668C8.93897 5.20668 9.16564 5.43335 9.16564 5.70668V9.70668C9.16564 9.98001 8.93897 10.2067 8.66564 10.2067C8.39231 10.2067 8.16564 9.98001 8.16564 9.70668V5.70668ZM9.16564 11.7067C9.16564 11.98 8.93897 12.2067 8.66564 12.2067C8.39231 12.2067 8.16564 11.98 8.16564 11.7067C8.16564 11.4333 8.39231 11.2067 8.66564 11.2067C8.93897 11.2067 9.16564 11.4333 9.16564 11.7067Z"
                  fill="#AB6400"
                />
              </svg>
              Project Unavailable
            </ErrorTitle>
            <ErrorMessage>
              Proof only supports one Org per firm. This project belongs to an
              Org that isn't linked to your account. To create a serve for this
              project, please contact us at <span>support@proofserve.com</span>.
              Otherwise, go to the Project Hub and select a project under a
              different Org.
            </ErrorMessage>
          </ErrorAlert>
        </ErrorContainer>
      );
    }

    if (error?.type === 'not_integrated') {
      return (
        <div
          style={{
            padding: '2rem',
            textAlign: 'center',
            maxWidth: '600px',
            margin: '0 auto',
          }}
        >
          <h2>Integration Not Configured</h2>
          <p>{error.message}</p>
          <SignOutContainer>
            <Button
              variant="secondary"
              onClick={() => {
                document.cookie = `${AUTH_TOKEN_COOKIE}=; path=/; domain=${COOKIE_DOMAIN}; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
                if (window.parent) {
                  window.parent.postMessage({ type: 'PROOF_LPMS_LOGOUT' }, '*');
                  if (posthog) {
                    posthog.reset();
                  }
                }
                window.location.reload();
              }}
            >
              Sign Out
            </Button>
          </SignOutContainer>
        </div>
      );
    }
  }

  return <AppRoutes />;
};

const App = ({ theme }) => {
  const [authToken, setAuthToken] = useState(null);
  const [firm, setFirm] = useState(null);
  const [loading, setLoading] = useState(true);
  usePostHogInit();

  // Use useCallback to stabilize these functions
  const handleFirmUpdate = useCallback((updates) => {
    setFirm((prev) => ({
      ...prev,
      ...updates,
    }));
  }, []);

  // Use useMemo for theme style
  const ThemeGlobalStyle = useMemo(() => theme.global, [theme]);

  // Consolidate auth token effect
  useEffect(() => {
    const cookieToken = getCookie(AUTH_TOKEN_COOKIE);
    if (cookieToken) {
      setAuthToken(cookieToken);
      axiosClient.defaults.headers['Authorization'] = cookieToken;
    }
    setLoading(false);
  }, []);

  // Separate firm loading effect
  useEffect(() => {
    if (authToken) {
      getCurrentLawFirm()
        .then(setFirm)
        .catch(console.error)
        .finally(() => setLoading(false));
    }
  }, [authToken]);

  // Early return for auth callback
  const params = new URLSearchParams(window.location.search);
  if (params.get('auth_callback')) {
    window.close();
    return null;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <React.Fragment>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <ThemeGlobalStyle />
          <ToastProvider>
            {!authToken ? (
              <MemoryRouter>
                <ErrorBoundary name="LoginRoutes">
                  <LoginRoutes />
                </ErrorBoundary>
              </MemoryRouter>
            ) : (
              <CurrentUserContextProvider>
                <ErrorBoundary name="ContentArea">
                  <MemoryRouter>
                    <ErrorBoundary name="EditFirmAddress">
                      <EditFirmAddress
                        open={firm && !isFirmAddressFullyPopulated(firm)}
                        firm={firm}
                        onSubmit={handleFirmUpdate}
                        onClose={handleFirmUpdate}
                      />
                    </ErrorBoundary>
                    <MatterContextProvider theme={theme}>
                      <UrqlProvider value={urqlClient}>
                        <AppContent theme={theme} />
                      </UrqlProvider>
                    </MatterContextProvider>
                  </MemoryRouter>
                </ErrorBoundary>
              </CurrentUserContextProvider>
            )}
          </ToastProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </React.Fragment>
  );
};

export default App;
