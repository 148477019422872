import React, { useMemo } from 'react';
import styled from 'styled-components';
import Button from '../../components/Button';
import { Required } from '../../components/Required';
import { formatNumber } from '../../utils/format';
import { SubSectionTitle } from './JobCreationScreen.styles';

export const ServePricing = ({
  serveeIndex,
  servee,
  setServeeField,
  currentUser,
}) => {
  const premiumServePricings = ['PREMIUM', 'PREMIUM_RUSH'];
  const premiumExcludedServePricings = ['STANDARD', 'EXPEDITED', 'SAME_DAY'];

  const priceOrder = useMemo(
    () =>
      new Map([
        [
          'PREMIUM',
          {
            order: 0,
            desc: 'First attempt within 3 calendar days.',
          },
        ],
        [
          'PREMIUM_RUSH',
          {
            order: 1,
            desc: 'First attempt will be made on the same day if received before 2pm.',
          },
        ],
        [
          'STANDARD',
          {
            order: 2,
            desc: 'Four (4) attempts over ten (10) days.',
          },
        ],
        [
          'EXPEDITED',
          {
            order: 3,
            desc: 'Four (4) attempts over three (3) days.',
          },
        ],
        [
          'SAME_DAY',
          {
            order: 4,
            desc: 'First attempt on same day if received before 2 PM.',
          },
        ],
        [
          'HYBRID',
          {
            order: 5,
            desc: 'Two (2) personal attempts before posting within four (4) days.',
          },
        ],
        [
          'POST',
          {
            order: 6,
            desc: 'Documents placed on door within two (2) days.',
          },
        ],
        [
          'GARNISHMENT',
          {
            order: 7,
            desc: 'Up to four (4) attempts at one (1) address over seven (7) days',
          },
        ],
      ]),
    [],
  );
  const prices = useMemo(() => {
    const predicate = (a, b) => {
      if (priceOrder.get(a.type)?.order < priceOrder.get(b.type)?.order) {
        return -1;
      }

      if (priceOrder.get(a.type)?.order > priceOrder.get(b.type)?.order) {
        return 1;
      }

      return 0;
    };

    return servee.serve_pricings
      .filter((price) => {
        if (currentUser?.law_firm?.premium) {
          return !premiumExcludedServePricings.includes(price.type);
        }
        return !premiumServePricings.includes(price.type);
      })
      .sort(predicate);
  }, [priceOrder, servee.serve_pricings, currentUser]);

  return (
    <>
      <SubSectionTitle>
        Choose a serve speed
        <Required />
      </SubSectionTitle>

      {servee.same_address !== false && (
        <div>
          Since this servee is at the same address as a previous servee, it will
          use that servee's selected serve speed.
        </div>
      )}

      {servee.same_address === false && (
        <>
          {servee.serve_pricings.length === 0 && (
            <ServePricingError>
              Please finish filling out the primary address for this servee to
              see serve speed & pricing options
            </ServePricingError>
          )}
          {servee.serve_pricings.length > 0 && (
            <ServePricings>
              {prices.map((price, j) => (
                <ServePrice
                  key={j}
                  selected={servee.serve_pricing_id === price.id}
                >
                  <ServePricingTitle>{price.name}</ServePricingTitle>
                  <ServePricingPrice>
                    ${formatNumber(price.base_fee + price.bounty_fee)}
                  </ServePricingPrice>
                  {!!price.bounty_fee && (
                    <ServePricingExtra>
                      Base Fee:${formatNumber(price.base_fee)}
                      <br />
                      Success Fee:$
                      {formatNumber(price.success_fee)}
                    </ServePricingExtra>
                  )}

                  <ServePricingAttempts>
                    {priceOrder.get(price.type)?.desc || price?.description}
                  </ServePricingAttempts>

                  {servee.serve_pricing_id !== price.id && (
                    <Button
                      variant="primary"
                      onClick={(e) => {
                        setServeeField(
                          serveeIndex,
                          'serve_pricing_id',
                          price.id,
                          e,
                        );
                        setServeeField(
                          serveeIndex,
                          'post_serve',
                          price.type === 'POST',
                          e,
                        );
                      }}
                    >
                      Select
                    </Button>
                  )}
                  {servee.serve_pricing_id === price.id && <b>Selected</b>}
                  {price.type === 'SAME_DAY' && (
                    <ServePricingDisclaimer>
                      * If received before 2 pm where documents will be served,
                      attempts will begin on the same day and be completed
                      within 24 hours to the best of the server's ability.
                    </ServePricingDisclaimer>
                  )}
                  {price.type === 'HYBRID' && (
                    <ServePricingDisclaimer>
                      * Required for Vacate and Demands jobs (i.e. Eviction,
                      Demand for Compliance, Late Notice, Notice to Quit, Notice
                      of Response Deadline)
                    </ServePricingDisclaimer>
                  )}
                </ServePrice>
              ))}
            </ServePricings>
          )}
        </>
      )}
    </>
  );
};

const ServePrice = styled.div`
  padding: 2rem;
  margin: 0.6rem;
  transition: background 200ms ease-in-out;
  background: #f5f7f8;
  border: 1px solid #e2ebee;
  flex: 0 0 calc(20% - 1.2rem); /* Set fixed width for all cards */
  text-align: center;
  ${({ selected }) => (selected ? `background: #abd8f6;` : ``)}
  min-width: 30rem;
  border-radius: 0.2rem;
`;
const ServePricingError = styled.div`
  font-weight: 500;
`;

const ServePricingTitle = styled.div`
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 1.2rem;
`;

const ServePricingPrice = styled.div`
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 1.2rem;
`;

const ServePricingExtra = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 0.8rem;
`;

const ServePricingDisclaimer = styled.div`
  font-size: 1.1rem;
  margin-top: 1.2rem;
`;

const ServePricingAttempts = styled.div`
  font-size: 1.4rem;
  margin-bottom: 1.2rem;
`;

const ServePricings = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: -0.6rem;
`;
