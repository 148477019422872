import React, { createContext, useState, useEffect } from 'react';
import { getCurrentUser } from 'utils/api';
import { datadogRum } from '@datadog/browser-rum';
import { getLPMSTheme } from 'utils/theme';
import { usePostHog } from 'posthog-js/react';

const CurrentUserContext = createContext(null);

const theme = getLPMSTheme();
const CurrentUserContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const posthog = usePostHog();

  useEffect(() => {
    getCurrentUser()
      .then((usr) => setUser(usr))
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (user) {
      datadogRum.setUser({
        id: user?.id,
        name: user?.name,
        email: user?.email,
        lpmsType: theme?.id,
      });
      if (VITE_POSTHOG_KEY) {
        posthog.identify(user.id, {
          name: user.name,
          email: user.email,
          type: user.type,
          state: user.state ? user.state.abbreviation : null,
          signup_date: Math.round(user.created_at / 1000),
          law_firm_id: user?.law_firm_id,
        });
        if (user.law_firm) {
          posthog.group('law_firm', user.law_firm_id, {
            name: user.law_firm.name,
            state: user.law_firm.state
              ? user.law_firm.state.abbreviation
              : null,
            signup_date: Math.round(user.law_firm.created_at / 1000),
          });
        }
      }
    }
  }, [user, posthog]);

  return (
    <CurrentUserContext.Provider value={user}>
      {children}
    </CurrentUserContext.Provider>
  );
};

export { CurrentUserContext, CurrentUserContextProvider };
