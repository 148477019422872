import { useCallback, useContext } from 'react';
import posthog from 'posthog-js';
import { CurrentUserContext } from 'context/CurrentUserContext';
import { logInfo } from './logger';

const getUserEventDetails = (currentUser) => {
  const data = {};
  if (currentUser) {
    data['userID'] = currentUser.id;
    data['userName'] = currentUser.name;
    data['userType'] = currentUser.type;
    if (currentUser.law_firm) {
      data['lawfirmID'] = currentUser.law_firm.id;
      data['lawfirmName'] = currentUser.law_firm.name;
    }
  }
  return data;
};

export const useTrackEvent = () => {
  const currentUser = useContext(CurrentUserContext);

  return useCallback(
    (event, data) => {
      const newData = {
        ...getUserEventDetails(currentUser),
        ...(data || {}),
      };
      logInfo('tracking event', { event, data: newData });
      posthog.capture(event, newData);
    },
    [currentUser],
  );
};
