import React, { useState, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { Table } from 'components/Table';
import Loader from 'components/Loader';
import { Link } from 'components/Link';
import { jobSpecColumns } from './JobSpecsTable.utils';

/* global APP_URL */

const pageSize = 5;

const TableWrapper = styled.div`
  margin-top: 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: 100%;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const EmptyStateContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;

const Text = styled.div`
  font-size: 1.4rem;
  font-weight: ${(props) => (props.weight === 'medium' ? 500 : 400)};
`;

const PaginationControls = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
`;

const PageButton = styled.button`
  padding: 0.4rem 0.8rem;
  border: 0.1rem solid #e0e0e0;
  background: ${(props) => (props.active ? '#f5f5f5' : 'white')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};

  &:hover:not(:disabled) {
    background: #f5f5f5;
  }
`;

const ManageLink = styled(Link)`
  font-size: 1.4rem;
`;

export const JobSpecsTable = ({
  specs = [],
  isLoading,
  onSelectSpec,
  selectedSpecs,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate pagination
  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    return specs.slice(startIndex, startIndex + pageSize);
  }, [specs, currentPage]);

  const totalPages = Math.ceil(specs.length / pageSize);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Handle row selection
  const handleSpecSelectionChange = useCallback(
    (specID) => {
      // if the spec is already selected, remove it
      if (selectedSpecs.includes(specID)) {
        onSelectSpec(selectedSpecs.filter((id) => id !== specID));
      } else {
        // if the spec is not selected, add it
        onSelectSpec([...selectedSpecs, specID]);
      }
    },
    [onSelectSpec, selectedSpecs],
  );

  const tableFields = useMemo(
    () => jobSpecColumns(selectedSpecs, handleSpecSelectionChange),
    [selectedSpecs, handleSpecSelectionChange],
  );

  if (isLoading) {
    return <Loader small />;
  }

  return (
    <TableWrapper>
      {Boolean(specs.length) && (
        <PaginationWrapper>
          <HeaderContent>
            <Text weight="medium">Saved Job Specifications</Text>
            <ManageLink target="_blank" to={`${APP_URL}admin#job-prefs`}>
              Manage Job Specifications
            </ManageLink>
          </HeaderContent>
        </PaginationWrapper>
      )}

      {!specs.length ? (
        <PaginationWrapper>
          <EmptyStateContent>
            <Text>
              Your firm does not have any custom specifications saved!
            </Text>
            <ManageLink target="_blank" to={`${APP_URL}admin#job-prefs`}>
              Add Job Specifications
            </ManageLink>
          </EmptyStateContent>
        </PaginationWrapper>
      ) : (
        <>
          <Table data={paginatedData} fields={tableFields} />

          {totalPages > 1 && (
            <PaginationWrapper>
              <Text>
                Showing {(currentPage - 1) * pageSize + 1} to{' '}
                {Math.min(currentPage * pageSize, specs.length)} of{' '}
                {specs.length}
              </Text>
              <PaginationControls>
                <PageButton
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  type="button"
                >
                  Previous
                </PageButton>
                {Array.from({ length: totalPages }).map((_, i) => {
                  const pageNum = i + 1;
                  return (
                    <PageButton
                      key={`page-${pageNum}`}
                      onClick={() => handlePageChange(pageNum)}
                      active={currentPage === pageNum}
                      type="button"
                    >
                      {pageNum}
                    </PageButton>
                  );
                })}
                <PageButton
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  type="button"
                >
                  Next
                </PageButton>
              </PaginationControls>
            </PaginationWrapper>
          )}
        </>
      )}
    </TableWrapper>
  );
};
