import React from 'react';
import styled from 'styled-components';

export const RadioGroup = ({ defaultValue, name, onChange, options }) =>
  options.map((option) => (
    <Label key={option.id}>
      <Input
        type="radio"
        name={name}
        checked={defaultValue === option.value}
        value={option.value}
        onChange={() => onChange(option.value)}
      />
      {option.label}
    </Label>
  ));

const Label = styled.label`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 1.3rem;
`;

const Input = styled.input`
  margin: 0.8rem;
`;
