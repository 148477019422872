import React from 'react';
import styled from 'styled-components';
import { Checkbox } from 'components/Checkbox';

const Badge = styled.span`
  padding: 0.4rem 0.8rem;
  border-radius: 0.4rem;
  font-size: 1.2rem;
  background: ${(props) => (props.color === 'blue' ? '#E3F2FD' : '#F5F5F5')};
  color: ${(props) => (props.color === 'blue' ? '#1976D2' : '#757575')};
`;

const BadgeWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.8rem;
`;

export const jobSpecColumns = (selectedSpecs, onSelect) => [
  {
    title: '',
    field: 'select',
    width: '3.2rem',
    display: (spec) => (
      <Checkbox
        value={selectedSpecs.includes(Number(spec.id))}
        onChange={(val) => onSelect(Number(spec.id), val)}
      />
    ),
  },
  {
    title: 'Title',
    field: 'title',
    width: '32rem',
    display: (spec) => {
      let value = spec.title;
      if (value?.length > 60) {
        value = value.slice(0, 60) + '...';
      }
      return value;
    },
  },
  {
    title: 'Job Type',
    field: 'jobType',
    width: '12.8rem',
    display: (spec) => {
      const value = spec.jobType;
      return value ? value[0] + value.slice(1).toLowerCase() : '';
    },
  },
  {
    title: 'Location',
    field: 'state.name',
    width: '12rem',
    display: (spec) => (
      <Badge>{spec.stateID ? spec.state?.name : 'All jobs'}</Badge>
    ),
  },
  {
    title: 'Enabled Preferences',
    field: 'jobpreferences',
    width: '25rem',
    display: (spec) => {
      const preferences = spec.jobpreferences;
      if (!preferences?.length) return null;

      return (
        <BadgeWrapper>
          {preferences.map((pref) => (
            <Badge
              key={pref.id}
              color={pref.type === 'CUSTOM' ? 'blue' : 'pewter'}
            >
              {pref.title}
            </Badge>
          ))}
        </BadgeWrapper>
      );
    },
  },
  {
    title: 'Job Spec ID',
    field: 'id',
    width: '10.8rem',
  },
  {
    title: 'Created By',
    field: 'createdby.name',
    width: '12rem',
  },
];
