import React, { useContext, useEffect, useState } from 'react';
import Logo from 'components/Logo';
import styled, { withTheme } from 'styled-components';
import Button from 'components/Button';
import { clio } from 'themes/clio';
import { myCase } from 'themes/mycase';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  background: ${({ theme, variant }) =>
    theme === myCase ? '#F5F5F5' : '#fff'};
  padding: 2rem;
  border-radius: 0.4rem;
  box-shadow: 0 1px 4px 0 rgb(38 50 56 / 20%);
  width: 56.5rem;
`;

const Message = styled.div`
  margin: 2rem 0;
  font-weight: 500;
  text-align: center;
  ul {
    margin-top: 0.6rem;
    display: inline-block;
    text-align: left;
    padding-left: 2.5rem;
  }
  li {
    padding-bottom: 0.4rem;
  }
`;

const Disclaimer = styled.div`
  margin-top: 4rem;
  font-size: 1.2rem;
  text-align: center;

  b {
    font-weight: bold;
  }
`;

const TroubleLoggingIn = styled.div`
  margin: 2rem 0;
  text-align: center;
  max-width: 50rem;

  a {
    color: #0070e0;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  margin: 1rem -1rem -1rem;

  > * {
    margin: 1rem;
  }
`;

const AuthButton = styled.button`
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid #ffbf2d;
  background: #ffdc7a;
  box-shadow:
    0px 1px 2px 0px #ffe7a3,
    0px 1px 0px 1px rgba(255, 255, 255, 0.4) inset;
  color: #131b2b;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  display: inline-block;
  position: relative;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  white-space: nowrap;
  z-index: 1;
  width: 100%;
  cursor: pointer;

  &:hover {
    background: #ffbf2d; // Slightly darker shade for hover
  }
`;

const LoginScreen = ({ theme }) => {
  const [isExtension] = useState(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get('isProofExtension') === 'true';
  });

  // Check for token to determine if we're authenticated
  const params = new URLSearchParams(window.location.search);
  const isAuthenticated = !!params.get('token');

  useEffect(() => {
    const token = params.get('token');
    console.log('LoginScreen init:', { isExtension, token });

    if (token && isExtension) {
      console.log('Sending setCookieRequest');
      window.parent.postMessage({ type: 'setCookieRequest', token }, '*');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isExtension) {
    if (isAuthenticated) {
      // Show neutral loading state instead of themed content
      return (
        <Root>
          <Container style={{ background: '#fff' }}>
            <Logo width="18rem" />
          </Container>
        </Root>
      );
    }

    // Not authenticated - show auth required
    return (
      <Root>
        <Container>
          <Logo width="18rem" />
          <Message>
            With the Proof Chrome Extension, your firm can now serve anyone,
            anywhere, directly from your LPMS.
            <br />
            <br />
            <b>Key features include:</b>
            <ul>
              <li>
                Complete nationwide coverage of all states & U.S. territories
              </li>
              <li>
                Serve documents & contacts already stored in your project /
                matter
              </li>
              <li>
                Track service attempts in real-time, with photo & gps
                verification
              </li>
              <li>Chat directly with Proof & your process server</li>
              <li>
                Affidavit of Service dropped directly into your
                project's/matter's documents
              </li>
              <li>Associated costs are automatically added as an expense</li>
            </ul>
          </Message>
          <AuthButton
            onClick={() =>
              window.parent.postMessage({ type: 'needsAuth' }, '*')
            }
            testID="retry-auth-button"
          >
            Sign in with Proof
          </AuthButton>
          <Disclaimer>
            <b>First time signing in?</b> Make sure you already have a Proof
            account with an active integration set up at{' '}
            <a href={APP_URL} target="_blank" rel="noreferrer">
              {APP_URL}
            </a>
          </Disclaimer>
        </Container>
        <TroubleLoggingIn>
          <b>Having trouble logging in?</b> Please visit{' '}
          <a href={APP_URL} target="_blank" rel="noreferrer">
            {APP_URL}
          </a>{' '}
          and sign out of your account, then return to your LPMS and try to
          connect. Your browser may be blocking third party cookies which are
          required for Proof's integration to work. Please read the following{' '}
          <a
            href="https://help.proofserve.com/client/en/articles/7212428-disabling-blocking-of-proof-cookies-for-case-management-integrations"
            target="_blank"
            rel="noreferrer"
          >
            support article
          </a>{' '}
          for instructions on how to add an exception for Proof, or feel free to
          contact support for help.
        </TroubleLoggingIn>
        <Footer>
          <Button variant="secondary" to="/support">
            Proof customer support
          </Button>
          <Button variant="secondary" to={APP_URL} target="_blank">
            Open Proof
          </Button>
        </Footer>
      </Root>
    );
  }

  return (
    <Root>
      <Container>
        <Logo width="18rem" />
        <Message>
          With the Proof integration for {theme.name}, your firm can now serve
          anyone, anywhere, directly from your {theme.name} {theme.matter}s.
          <br />
          <br />
          <b>Key features include:</b>
          <ul>
            <li>
              Complete nationwide coverage of all states & U.S. territories
            </li>
            <li>
              Serve documents & contacts already stored in your {theme.name}{' '}
              {theme.matter}
            </li>
            <li>
              Track service attempts in real-time, with photo & gps verification
            </li>
            <li>Chat directly with Proof & your process server</li>
            <li>
              Affidavit of Service dropped directly into your {theme.name}{' '}
              {theme.matter} documents
            </li>
            <li>
              Associated costs are automatically added as an expense within{' '}
              {theme.name}
            </li>
          </ul>
        </Message>
        <Button
          variant="primary"
          size="big"
          onClick={() => {
            const w = 1200;
            const h = 600;
            const dualScreenLeft =
              window.screenLeft !== undefined
                ? window.screenLeft
                : window.screenX;
            const dualScreenTop =
              window.screenTop !== undefined
                ? window.screenTop
                : window.screenY;
            const width = window.innerWidth
              ? window.innerWidth
              : document.documentElement.clientWidth
                ? document.documentElement.clientWidth
                : screen.width;
            const height = window.innerHeight
              ? window.innerHeight
              : document.documentElement.clientHeight
                ? document.documentElement.clientHeight
                : screen.height;
            const systemZoom = width / window.screen.availWidth;
            const top = (height - h) / 2 / systemZoom + dualScreenTop;
            const left = (width - w) / 2 / systemZoom + dualScreenLeft;

            const win = window.open(
              `${AUTH_URL}${theme.id.toLowerCase()}/login?redirect_url=${encodeURIComponent(`${LPMS_URL}?auth_callback=true&lpms=${theme.id.toLowerCase()}`)}`,
              '',
              `width=${w},height=${h},top=${top},left=${left}`,
            );
            setInterval(() => {
              if (win.closed) {
                window.location.reload();
              }
            }, 100);
          }}
        >
          {theme === clio
            ? `Connect to ${theme.name}`
            : `Sign in with ${theme.name}`}
        </Button>
        <Disclaimer>
          <b>
            First time signing in and you already have an account with Proof?
          </b>{' '}
          Sign in with your {theme.name} account, and as long as the email
          address on your {theme.name} account matches the email of your
          existing Proof account, your accounts will automatically be linked!
        </Disclaimer>
      </Container>
      <TroubleLoggingIn>
        <b>Having trouble logging in?</b> Your browser may be blocking third
        party cookies which are required for Proof's {theme.name} integration to
        work. Please read the following{' '}
        <a
          href="https://help.proofserve.com/client/en/articles/7212428-disabling-blocking-of-proof-cookies-for-case-management-integrations"
          target="_blank"
          rel="noreferrer"
        >
          support article
        </a>{' '}
        for instructions on how to add an exception for Proof, or feel free to
        contact support for help.
      </TroubleLoggingIn>
      <Footer>
        <Button variant="secondary" to="/support">
          Proof customer support
        </Button>
        <Button variant="secondary" to={APP_URL} target="_blank">
          Open Proof
        </Button>
      </Footer>
    </Root>
  );
};

export default withTheme(LoginScreen);
