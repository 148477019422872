import React, { useEffect, useMemo, useState } from 'react';
import { RadioGroup } from 'components/RadioGroup';
import {
  InputRow,
  Label,
  Section,
  SectionBody,
  SectionHeader,
  SectionHeaderIcon,
  SectionHeaderTitle,
} from '../JobCreationScreen.styles';
import { useJobSpecs } from './JobSpecs.hooks';
import { JobSpecsTable } from './JobSpecsTable';

const serveJobTypes = [
  'STANDARD',
  'EXPEDITED',
  'SAME_DAY',
  'PREMIUM',
  'PREMIUM_RUSH',
  'HYBRID',
  'GARNISHMENT',
];
const postingJobTypes = ['ENVELOPE', 'POST'];
const allJobTypes = [...serveJobTypes, ...postingJobTypes];

export const JobSpecs = ({
  lawFirmID,
  courtStateID,
  serveeStateID,
  jobType,
  currentSpecs,
  onSpecChange,
}) => {
  const { data: specsData } = useJobSpecs(lawFirmID, {
    enabled: Boolean(lawFirmID),
  });
  const [specificationType, setSpecificationType] = useState('default');

  // Determines which JobSpecsTable rows should be selected
  const autoSelectedSpecs = useMemo(() => {
    // If no specs data is available, return empty array
    if (!specsData?.jobSpecs) {
      return [];
    }

    return specsData.jobSpecs
      .filter((spec) => {
        // If jobType isn't in our known types, assume SERVE and filter out POSTING specs
        if (!allJobTypes.includes(jobType)) {
          return spec.jobType === 'SERVE';
        }

        // If it is a known type, determine if it's a POSTING or SERVE job
        const isPostingJob = postingJobTypes.includes(jobType);
        const expectedSpecType = isPostingJob ? 'POSTING' : 'SERVE';

        // Only return specs that match the expected type
        return spec.jobType === expectedSpecType;
      })
      .filter((spec) => {
        const specStateID = parseInt(spec.stateID);
        // Include specs that are not state-specific
        if (!specStateID) {
          return true;
        }

        // Include court-specific specs that match the selected court state
        if (spec.stateType === 'COURT' && specStateID === courtStateID) {
          return true;
        }

        // Include service address specs that match the selected servee state
        if (
          spec.stateType === 'SERVE_ADDRESS' &&
          specStateID === serveeStateID
        ) {
          return true;
        }

        return false;
      })
      .map((spec) => Number(spec.id));
  }, [courtStateID, jobType, serveeStateID, specsData?.jobSpecs]);

  // Reset specs when job data changes
  useEffect(() => {
    if (!specsData?.jobSpecs) return;

    const shouldUseCustomSpecs = autoSelectedSpecs.length > 0;
    const newSpecType = shouldUseCustomSpecs ? 'custom' : 'default';
    const newSpecs = shouldUseCustomSpecs ? autoSelectedSpecs : [];

    setSpecificationType(newSpecType);
    onSpecChange(newSpecs);
  }, [specsData?.jobSpecs, autoSelectedSpecs, onSpecChange]);

  const onSpecificationTypeChange = (jobSpecsType) => {
    setSpecificationType(jobSpecsType);

    if (jobSpecsType === 'default') {
      onSpecChange([]);
    } else {
      onSpecChange(autoSelectedSpecs);
    }
  };

  return (
    <Section>
      <SectionHeader>
        <SectionHeaderTitle>
          <SectionHeaderIcon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M6 3h12a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2Zm0 2v14h12V5H6Zm2 2h8v2H8V7Zm0 4h8v2H8v-2Zm0 4h5v2H8v-2Z" />
            </svg>
          </SectionHeaderIcon>
          Job Specifications
        </SectionHeaderTitle>
      </SectionHeader>
      <SectionBody>
        <Label>
          Do you have custom preferences for this job? Note, most users choose
          to follow the default job specifications.
        </Label>
        <InputRow>
          <RadioGroup
            name="custom-or-default-specs"
            defaultValue={specificationType}
            options={[
              {
                id: 'default',
                label: 'Do not apply custom specifications (most common)',
                value: 'default',
              },
              {
                id: 'custom',
                label: 'Apply custom specifications',
                value: 'custom',
              },
            ]}
            onChange={onSpecificationTypeChange}
          />

          {specificationType === 'custom' && (
            <JobSpecsTable
              onSelectSpec={onSpecChange}
              selectedSpecs={currentSpecs}
              specs={specsData?.jobSpecs}
              isLoading={!specsData}
            />
          )}
        </InputRow>
      </SectionBody>
    </Section>
  );
};
