import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { Checkbox } from '../../components/Checkbox';
import Button from '../../components/Button';
import { uploadServeePhoto } from '../../utils/api';
import { Table } from '../../components/Table';
import { DocumentDropZone, InputRow, Label } from './JobCreationScreen.styles';

export const ServeePhotos = ({ serveeIndex, servee, setServeeField }) => {
  const [show, setShow] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      uploadServeePhoto(file)
        .then((resp) => {
          setServeeField(serveeIndex, 'servee_photos', [
            ...servee.servee_photos,
            { ...resp, preview: URL.createObjectURL(file) },
          ]);
        })
        .catch(console.error);
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg'],
    },
    onDrop,
  });

  return (
    <>
      <InputRow>
        <Checkbox
          title="Add Photos (optional)"
          value={show}
          onChange={() => setShow(!show)}
        />
      </InputRow>

      {show && (
        <InputRow>
          <Label>Upload servee photos</Label>
          <DocumentDropZone {...getRootProps()} active={isDragActive}>
            <input {...getInputProps()} />
            <div>
              Drag 'n' drop files from your computer, or&nbsp;
              <Button
                variant="secondary"
                size="small"
                onClick={(e) => e.preventDefault()}
              >
                Click here to upload
              </Button>
              <ServeePhotoSpecs>PNG or JPG (max. 50 MB)</ServeePhotoSpecs>
            </div>
          </DocumentDropZone>
        </InputRow>
      )}

      {Boolean(servee.servee_photos.length) && (
        <InputRow>
          <Table
            fields={[
              {
                title: 'Photo',
                display: (photo) => (
                  <img src={photo.preview} alt="servee-img" width={80} />
                ),
              },
              {
                title: 'Remove',
                display: (photo) => (
                  <Button
                    variant="destructive"
                    onClick={(e) => {
                      e.preventDefault();
                      setServeeField(
                        serveeIndex,
                        'servee_photos',
                        servee.servee_photos.filter((p) => p.id !== photo.id),
                      );
                    }}
                  >
                    Remove
                  </Button>
                ),
              },
            ]}
            data={servee.servee_photos}
          />
        </InputRow>
      )}
    </>
  );
};

export const ServeePhotoSpecs = styled.p`
  text-align: center;
  font-weight: 400;
`;
