import React from 'react';

const Logo = ({width = '12.5rem'}) => (
    <img 
        src="/images/logo.png"
        alt="Proof"
        style={{
            width: width,
            height: 'auto'
        }}
    />
);

export default Logo;