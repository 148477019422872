import { gql } from '@urql/core';
import { useQuery } from '@tanstack/react-query';
import { client as urqlClient } from 'graphql/client';

export const getJobSpecsByLawFirm = (lawFirmID) =>
  urqlClient
    .query(
      gql(`
        query getJobSpecsByLawFirm($lawFirmID: Int!) {
          jobSpecifications(where: { lawFirmID: $lawFirmID }) {
            edges {
              node {
                id
                lawFirmID
                title
                stateID
                state {
                  abbreviation
                  name
                }
                stateType
                jobType
                jobpreferences {
                  id
                  lawFirmID
                  type
                  title
                  description
                  jobType
                  legalRequirementID
                  stakeholder
                  inputType
                  createdAt
                  updatedAt
                }
                createdByID
                createdby {
                  name
                }
              }
            }
            totalCount
          }
        }
      `),
      { lawFirmID },
    )
    .toPromise();

export const useJobSpecs = (lawFirmID, options) =>
  useQuery({
    queryKey: ['job-specs', lawFirmID],
    queryFn: () => getJobSpecsByLawFirm(lawFirmID),
    select: (data) => ({
      jobSpecs: data?.data?.jobSpecifications?.edges.map((js) => js.node),
      totalCount: data?.data?.jobSpecifications?.totalCount,
    }),
    ...options,
  });
