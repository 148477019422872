import styled, { css } from 'styled-components';
import { clio } from 'themes/clio';
import { myCase } from 'themes/mycase';

export const Root = styled.div`
  position: relative;
  padding-top: 4.8rem;
`;

export const Header = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  z-index: 2;
  padding: 0 2rem;
  height: 4.8rem;
  box-shadow: 0 1px 4px 0 rgb(38 50 56 / 20%);
  border-bottom: 1px solid #ccd9e0;

  h1 {
    color: #263238;
    font-size: 2rem;
    margin: 0;
  }
`;

export const HeaderButtons = styled.div`
  > *:not(:last-child) {
    margin-right: 0.8rem;
  }
`;

export const Form = styled.form`
  margin-top: 2rem;
  padding-bottom: 10rem;
`;

const variantSectionStyles = (theme) => {
  switch (theme) {
    case myCase:
      return css`
        background-color: ${theme.colors.white};
        border: 1px solid #ddd;
        border-radius: 4px;
      `;
    case clio:
    default:
      return css``;
  }
};

export const Section = styled.div`
  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }
  ${({ theme, variant }) => variantSectionStyles(theme, variant)}
`;

export const Errors = styled.ul`
  color: #e41b28;
  margin: auto;
  width: max-content;
`;

export const SectionHeaderTitle = styled.div`
  font-weight: 700;
  font-size: 1.4rem;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
`;

const variantSectionHeaderStyles = (theme) => {
  switch (theme) {
    case myCase:
      return css`
        background: rgba(0, 0, 0, 0.03);
        font-size: 1.6rem;
        font-weight: 700;
        padding: 0.8rem 2rem;
        border-bottom: 1px solid #ddd;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      `;
    case clio:
    default:
      return css`
        margin-bottom: 1.2rem;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
      `;
  }
};

export const SectionHeader = styled.div`
  margin-bottom: 1.2rem;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  ${({ theme, variant }) => variantSectionHeaderStyles(theme, variant)}
`;

const variantSectionHeaderIconStyles = (theme) => {
  switch (theme) {
    case myCase:
      return css`
        display: none;
      `;
    case clio:
    default:
      return css`
        width: 3rem;
        height: 3rem;
        background: #abd8f6;
        border-radius: 50%;
        margin-right: 0.8rem;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          height: 1.4rem;
          width: auto;
        }
      `;
  }
};

export const SectionHeaderIcon = styled.div`
  ${({ theme, variant }) => variantSectionHeaderIconStyles(theme, variant)}
`;

const variantSectionBodyIconStyles = (theme) => {
  switch (theme) {
    case myCase:
      return css`
        border-bottom-left-radius: 0.4rem;
        border-bottom-right-radius: 0.4rem;
      `;
    case clio:
    default:
      return css`
        border: 1px solid #e2ebee;
        box-shadow: 0 2px 4px rgb(0 70 112 / 5%);
      `;
  }
};

export const SectionBody = styled.div`
  background: #fff;
  padding: 2rem;
  ${({ theme, variant }) => variantSectionBodyIconStyles(theme, variant)}
`;

export const AddServeeContainer = styled.div`
  margin-top: 1.2rem;
  text-align: right;
`;

export const InputGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }

  > * {
    flex: 1;
    margin-bottom: 0 !important;

    &:not(:last-child) {
      margin-right: 1.2rem;
    }
  }
`;

export const InputRow = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }
`;

export const PaymentMethodOptions = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.2rem;
`;

export const AddPaymentMethodButton = styled.div`
  margin-right: 1.2rem;
`;

export const DocumentDropZone = styled.div`
  transition: border 200ms ease-in-out;
  border: 3px dashed ${({ active }) => (active ? '#006cd8' : '#e2ebee')};
  padding: 2rem;
  height: 10rem;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Label = styled.label`
  font-weight: 700;
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const SubSectionTitle = styled.div`
  font-weight: 700;
  font-size: 1.4rem;
  margin-top: ${({ noMargin }) => (noMargin ? `0` : `3.6rem`)};
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const InputOptions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  > * {
    flex: 1;
    margin-bottom: 0 !important;
  }
`;

export const DocumentsContainer = styled.div`
  margin-top: 2.4rem;
`;

export const DocumentsMessage = styled.div`
  font-weight: 500;
`;

export const InputOptionsDivider = styled.div`
  margin: 0 2rem;
  flex: 0;
  white-space: nowrap;
`;

export const Addresses = styled.div`
  margin: 1.2rem 0;
`;

export const Address = styled.div`
  border-bottom: 1px solid #e2ebee;
  padding: 2.4rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  > :first-child {
    flex: 1;
    padding-right: 2rem;
  }

  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }
`;
